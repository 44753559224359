import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Quicksand\",\"arguments\":[{\"display\":\"swap\",\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\",\"latin-ext\"],\"variable\":\"--font-quicksand\"}],\"variableName\":\"quicksand\"}");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/lib/components/CookieBanner/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/lib/components/ForgotPasswordModal/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/lib/components/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/lib/components/LoginModal/index.tsx");
